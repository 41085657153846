/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import { JSONSupport as t } from "../core/JSONSupport.js";
import { clone as r } from "../core/lang.js";
import { getMetersPerUnitForSR as i, getUnitString as o } from "../core/unitUtils.js";
import { Integer as s } from "../core/accessorSupport/ensureType.js";
import { property as n } from "../core/accessorSupport/decorators/property.js";
import { subclass as l } from "../core/accessorSupport/decorators/subclass.js";
import { writer as a } from "../core/accessorSupport/decorators/writer.js";
import { wgs84 as p, webMercator as u, plateCarree as c, isWGS84 as d, isWebMercator as m, isGeographic as w, isWrappable as y, equals as k } from "./support/spatialReferenceUtils.js";
var h;
let S = h = class extends t {
  static fromJSON(e) {
    if (!e) return null;
    if (e.wkid) {
      if (102100 === e.wkid) return h.WebMercator;
      if (4326 === e.wkid) return h.WGS84;
    }
    const t = new h();
    return t.read(e), t;
  }
  constructor(e) {
    super(e), this.latestWkid = null, this.wkid = null, this.wkt = null, this.wkt2 = null, this.vcsWkid = null, this.latestVcsWkid = null, this.imageCoordinateSystem = null;
  }
  normalizeCtorArgs(e) {
    if (e && "object" == typeof e) return e;
    const t = "string" == typeof e ? "wkt" : "wkid";
    return {
      [t]: e
    };
  }
  get isWGS84() {
    return d(this);
  }
  get isWebMercator() {
    return m(this);
  }
  get isGeographic() {
    return w(this);
  }
  get isWrappable() {
    return y(this);
  }
  get metersPerUnit() {
    return i(this);
  }
  get unit() {
    return o(this) || (this.isGeographic ? "degrees" : null);
  }
  writeWkt(e, t) {
    this.wkid || (t.wkt = e);
  }
  clone() {
    if (this === h.WGS84) return new h(p);
    if (this === h.WebMercator) return new h(u);
    const e = new h();
    return null != this.wkid ? (e.wkid = this.wkid, null != this.latestWkid && (e.latestWkid = this.latestWkid), null != this.vcsWkid && (e.vcsWkid = this.vcsWkid), null != this.latestVcsWkid && (e.latestVcsWkid = this.latestVcsWkid)) : (null != this.wkt && (e.wkt = this.wkt), null != this.wkt2 && (e.wkt2 = this.wkt2)), this.imageCoordinateSystem && (e.imageCoordinateSystem = r(this.imageCoordinateSystem)), e;
  }
  equals(e) {
    if (null == e) return !1;
    if (this.imageCoordinateSystem || e.imageCoordinateSystem) {
      if (null == this.imageCoordinateSystem || null == e.imageCoordinateSystem) return !1;
      const {
          id: t,
          referenceServiceName: r
        } = e.imageCoordinateSystem,
        {
          geodataXform: i
        } = e.imageCoordinateSystem,
        o = this.imageCoordinateSystem;
      return null == t || i ? JSON.stringify(o) === JSON.stringify(e.imageCoordinateSystem) : r ? o.id === t && o.referenceServiceName === r : o.id === t;
    }
    return k(this, e);
  }
  toJSON(e) {
    return this.write(void 0, e);
  }
};
S.GCS_NAD_1927 = null, S.WGS84 = null, S.WebMercator = null, S.PlateCarree = null, e([n({
  readOnly: !0
})], S.prototype, "isWGS84", null), e([n({
  readOnly: !0
})], S.prototype, "isWebMercator", null), e([n({
  readOnly: !0
})], S.prototype, "isGeographic", null), e([n({
  readOnly: !0
})], S.prototype, "isWrappable", null), e([n({
  type: s,
  json: {
    write: !0
  }
})], S.prototype, "latestWkid", void 0), e([n({
  readOnly: !0
})], S.prototype, "metersPerUnit", null), e([n({
  readOnly: !0
})], S.prototype, "unit", null), e([n({
  type: s,
  json: {
    write: !0,
    origins: {
      "web-scene": {
        write: {
          overridePolicy() {
            return {
              isRequired: null == this.wkt
            };
          }
        }
      }
    }
  }
})], S.prototype, "wkid", void 0), e([n({
  type: String,
  json: {
    origins: {
      "web-scene": {
        write: {
          overridePolicy() {
            return {
              isRequired: null == this.wkid
            };
          }
        }
      }
    }
  }
})], S.prototype, "wkt", void 0), e([a("wkt"), a("web-scene", "wkt")], S.prototype, "writeWkt", null), e([n({
  type: String,
  json: {
    write: !1
  }
})], S.prototype, "wkt2", void 0), e([n({
  type: s,
  json: {
    write: !0
  }
})], S.prototype, "vcsWkid", void 0), e([n({
  type: s,
  json: {
    write: !0
  }
})], S.prototype, "latestVcsWkid", void 0), e([n()], S.prototype, "imageCoordinateSystem", void 0), S = h = e([l("esri.geometry.SpatialReference")], S), S.prototype.toJSON.isDefaultToJSON = !0, S.GCS_NAD_1927 = new S({
  wkid: 4267,
  wkt: 'GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]]'
}), S.WGS84 = new S(p), S.WebMercator = new S(u), S.PlateCarree = new S(c), Object.freeze && (Object.freeze(S.GCS_NAD_1927), Object.freeze(S.WGS84), Object.freeze(S.WebMercator), Object.freeze(S.PlateCarree));
const f = S;
export { f as default };